
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddPurchaseContractModal from "@/components/modals/forms/AddPurchaseContractModal.vue";
import AddProductListSalesEnquiry from "@/components/modals/forms/AddProductListSalesEnquiry.vue";
// import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import moment from "moment";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { array } from "yup/lib/locale";
import JwtService from "@/core/services/JwtService";
import { Modal } from "bootstrap";
import { useRoute, useRouter } from "vue-router";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
import { indexOf } from "lodash";

export default defineComponent({
  name: "apps-purchase-register-listing",
   props: {
    data: {
        type: Object
    }
  },
  components: {
    ExportCustomerModal,
    AddPurchaseContractModal,
    AddProductListSalesEnquiry,
    MixedWidget10,
    // Datatable,
  },
  // data() {
  //   return {
  //     purchase_type : [{delete_select : ""}]
  //   }
  // },
  // methods : {
  //   addField(id, fieldType) {
  //     fieldType.push({ id: indexOf });
  //   },
  // },
  methods: {
    onChange() {
      if (this.formData.startDate == "") {
        this.getCompanyList('')
      }
    },
  },

  setup(props) {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(true);
    const searchTearm = ref("");
    const router = useRouter();
    const listVisible = ref<boolean>(true);
    const formData = ref({
      startDate: "",
      endDate: "",
      pi_no: "",
    });

    interface WIJournal {
      purchase_entry_id: string;
      invoice_date: string;
      supplier_name: string;
      supplier_gst: string;
      warehouse: string;
      grn_no: string;
      grn_date: string;
      incoterm: string;
      payment_term: string;
      transporter: string;
      vehicle_no: string;
      origin: string;
      destination: string;
      pi_remarks: string;
      maintain_by: string;
      grn_remarks: string;
      product_total_amount: string;
    }

    interface WICompanies {
      company_id: number;
      user: {
        avatar: string;
        name: string;
        group: string;
      };
      comp_type: string;
      status: {
        label: string;
        color: string;
      };
      active: {
        label: string;
        color: string;
      };
      roc: string;
      nob: string;
      doi: string;
      pan: string;
    }

    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
    };

    const refreshData = () => {
      paginationData.value["activePage"] = 1;
      getCompanyList(searchTearm.value);
    };

    const changePageChange = (page) => {
      paginationData.value["activePage"] = page;
      getCompanyList(searchTearm.value);
    };
    

    var tableData = ref<Array<WIJournal>>([]);

    const getCompanyList = async (data) => {
      var user = JSON.parse(JwtService.getToken());
      // loadingData.value = true;
      var start_data = ""
        var end_date = ""
      if(!formData.value['startDate']){
          start_data = ""
          end_date = ""
      }
      else
      {
        start_data = moment(formData.value.startDate[0]).format("YYYY-MM-DD")
        end_date = moment(formData.value.startDate[1]).format("YYYY-MM-DD")
      }
      try {
        var values = {
          user_id: user.user_id,
          from_date: start_data,
          to_date: end_date,
          //pi_no: formData.value.pi_no,
          page: parseInt(paginationData.value["activePage"]),
          records_per_page: parseInt(paginationData.value["perPage"]),
          // records_per_page: 1000,
        };
        
        await store
          .dispatch(ActionsFi.CUST_GET_PURCHASE_REGISTER_LISTING, values)
          .then((data) => {
           
            console.log("total data:" + JSON.stringify(data));
            
            tableData.value = [];
            const totalCount = data?.data?.total_records;
            // set pagination
            paginationData.value["total"] = data?.data?.total_records;
            paginationData.value["start"] = data?.data?.records_from;
            paginationData.value["end"] = data?.data?.records_upto;
            paginationData.value["activePage"] = data?.data?.page;
            paginationData.value["totPage"] = data?.data?.total_pages;
            paginationData.value["pageRange"] = [];
            for (
              let index = data?.data?.bar_range_start;
              index < data?.data?.bar_range_end;
              index++
            ) {
              paginationData.value["pageRange"].push(index);
            }
            //endpagination
            data = data?.data?.result_list;

            var resultsM = ref<Array<WIJournal>>([]);
            var status_label = "";
            var status_color = "";
            var active_label = "";
            var active_color = "";

            for (let j = 0; j < data.length; j++) {
              resultsM.value = Array({
                purchase_entry_id: data[j]["purchase_entry_id"],
                invoice_date: data[j]["invoice_date"],
                supplier_name: data[j]["supplier_name"],
                pq_no: data[j]["pq_no"],
                supplier_gst: data[j]["supplier_gst"],
                warehouse: data[j]["warehouese"],
                grn_no: data[j]["grn_no"],
                grn_date: data[j]["grn_date"],
                incoterm: data[j]["incoterm"],
                payment_term: data[j]["payment_term"],
                transporter: data[j]["transporter"],
                vehicle_no: data[j]["vehicle_no"],
                origin: data[j]["origin"],
                destination: data[j]["destination"],
                pi_remarks: data[j]["pi_remarks"],
                grn_remarks: data[j]["grn_remarks"],
                maintain_by: data[j]["maintain_by"],
                product_total_amount: data[j]["product_total_amount"],
                // is_approved: data[j]["is_approved"] === 0 ? false : true,
              });
              console.log("resultsm:" + JSON.stringify(resultsM.value));
              tableData.value.splice(
                j,
                resultsM.value.length,
                ...resultsM.value
              );
            }

            loadingData.value = false;
          })
          .catch(({ response }) => {
            tableData.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };
    // const getCompanyList = async (data) => {
    //   loadingData.value = true;

    //   try {
    //     var values = {
    //       company_id: 0,
    //       search_term: data,
    //       page: parseInt(paginationData.value["activePage"]),
    //       records_per_page: parseInt(paginationData.value["perPage"]),
    //     };

    //     await store
    //       .dispatch(Actions.CUST_GET_COMPANY_LIST, values)
    //       .then(({ data }) => {
    //         JwtService.saveUserData({
    //           rakesh_count: data.rakesh_count,
    //           nikhil_count: data.nikhil_count,
    //           mansi_count: data.mansi_count,
    //         });
    //         console.log(JwtService.getUserData());

    //         tableData.value = [];

    //         // set pagination
    //         paginationData.value["total"] = data.total_records;
    //         paginationData.value["start"] = data.records_from;
    //         paginationData.value["end"] = data.records_upto;
    //         paginationData.value["activePage"] = data.page;
    //         paginationData.value["totPage"] = data.total_pages;
    //         paginationData.value["pageRange"] = [];
    //         for (
    //           let index = data.bar_range_start;
    //           index < data.bar_range_end;
    //           index++
    //         ) {
    //           paginationData.value["pageRange"].push(index);
    //         }
    //         //endpagination

    //         var resultsM = ref<Array<WICompanies>>([]);
    //         var status_label = "";
    //         var status_color = "";
    //         var active_label = "";
    //         var active_color = "";

    //         for (let j = 0; j < data.result_list.length; j++) {
    //           if (data.result_list[j]["company_verified_yes_no"]) {
    //             status_label = "Verified";
    //             status_color = "success";
    //           } else {
    //             status_label = "Unverified";
    //             status_color = "danger";
    //           }

    //           if (data.result_list[j]["active"]) {
    //             active_label = "Yes";
    //             active_color = "success";
    //           } else {
    //             active_label = "No";
    //             active_color = "danger";
    //           }

    //           resultsM.value = Array({
    //             company_id: data.result_list[j]["company_id"],
    //             user: {
    //               group: data.result_list[j]["company_group_type_name"],
    //               avatar:
    //                 "https://cdn.elogicals.com/customers/" +
    //                 data.result_list[j]["company_id"] +
    //                 "/logo/" +
    //                 data.result_list[j]["company_id"] +
    //                 ".png",
    //               name: data.result_list[j]["company_name"],
    //             },
    //             comp_type: data.result_list[j]["company_business_type_name"],
    //             status: {
    //               label: status_label,
    //               color: status_color,
    //             },
    //             active: {
    //               label: active_label,
    //               color: active_color,
    //             },
    //             roc: data.result_list[j]["company_roc_name"],
    //             nob: data.result_list[j]["company_nob_name"],
    //             doi: data.result_list[j]["company_doi"],
    //             pan: data.result_list[j]["company_pan"],
    //           });

    //           tableData.value.splice(
    //             j,
    //             resultsM.value.length,
    //             ...resultsM.value
    //           );
    //         }

    //         // tableData.value = [{
    //         //   company_id: 1,
    //         //   user: {
    //         //     avatar: "string;",
    //         //     name: "string;",
    //         //     group: "string;",
    //         //   },
    //         //   comp_type: "string;",
    //         //   status: {
    //         //     label: "string;",
    //         //     color: "string;",
    //         //   },
    //         //   active: {
    //         //     label: "string;",
    //         //     color: "string;,"
    //         //   },
    //         //   roc: "string,",
    //         //   nob: "string,",
    //         //   doi: "string,",
    //         //   pan: "string,"
    //         // }];

    //         loadingData.value = false;
    //       })
    //       .catch(({ response }) => {
    //         tableData.value = [];
    //         loadingData.value = false;
    //       });
    //   } catch (e) {
    //     console.log(e);
    //   }
    // };

    // onUpdated(() => {          });

    // var tableData = ref<Array<WICompanies>>([]);
    const initCustomers = ref<Array<WIJournal>>([]);

    onMounted(async () => {
      await getCompanyList(searchTearm.value);
      setCurrentPageBreadcrumbs("Purchase Register", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    const deleteFewCustomers = () => {
      checkedCompany.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCompany.value.length = 0;
    };

    const deleteCustomer = (id) => {
      // for (let i = 0; i < tableData.value.length; i++) {
      //   if (tableData.value[i].company_id === id) {
      //     tableData.value.splice(i, 1);
      //   }
      // }
    };

    const product_add_modal = (sales_enquiry_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_add_product_sales_enquiry")
      );
      modal.show();
      (document.querySelector("#sales_enquiry_id") as HTMLInputElement).value =
        sales_enquiry_id;
    };

    const search = ref<string>("");
    const searchItems = () => {
      searchTearm.value = search.value;
      getCompanyList(search.value);
      console.log(search.value);
      // tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      // if (search.value !== "") {
      //   let results: Array<WICompanies> = [];
      //   for (let j = 0; j < tableData.value.length; j++) {
      //     if (searchingFunc(tableData.value[j], search.value)) {
      //       results.push(tableData.value[j]);
      //     }
      //   }
      //   tableData.value.splice(0, tableData.value.length, ...results);
      // }
    };

    function showList() {
      listVisible.value = true;
      console.log("list visible.." + showList);
    }
    function hideList() {
      listVisible.value = false;
    }

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    async function approveContract(purchase_entry_id) {
      var user = JSON.parse(JwtService.getToken());
      let params = {
        purchase_entry_id: purchase_entry_id,
        user_id: user.user_id,
      };
      await store
        .dispatch(ActionsFi.CUST_APPROVE_PURCHASE_CONTRACT, params)
        .then(({ data }) => {
          getCompanyList(searchTearm.value);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const Pdfdata = async (id) => {
      var user = JSON.parse(JwtService.getToken());
      const db_data = {
        "user_id" : user.user_id,
        "doc_type" : 4,
        "doc_id" : id
      };
      console.log("DB DATA")
      console.log(db_data)
      //loading.value = true;
      try {
        await store
          .dispatch(ActionsFi.CUST_PDF_REQUEST, db_data)
          .then(({ data }) => {
           console.log("DATATATATA")
           console.log(data.request_id)
           console.log('https://print.elogicals.com/print/pc/' +db_data.doc_id + '/' +db_data.user_id + '/' + data.request_id)
            //loading.value = false;
             window.open('https://print.elogicals.com/print/pc/' +db_data.doc_id + '/' +db_data.user_id + '/' + data.request_id )
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    return {
      tableData,
      deleteCustomer,
      search,
      searchItems,
      checkedCompany,
      deleteFewCustomers,
      count,
      getCompanyList,
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      product_add_modal,
      showList,
      listVisible,
      hideList,
      formData,
      router,
      approveContract,
      Pdfdata
    };
  },
});
